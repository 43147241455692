import React from "react";

type Props = React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
>;

const ResponsiveIframe = ({ title, ...props }: Props) => (
    <div className="embed-responsive embed-responsive-16by9 rounded shadow-2xl">
        <iframe
            className={"shadow-xl rounded embed-responsive-item"}
            title={title}
            {...props}
        />
    </div>
);

export default ResponsiveIframe;
