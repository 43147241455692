import React, { CSSProperties } from "react";
import Layout from "./Layout";
import SEO from "./SEO";
import { Link } from "gatsby";
import { ABOUT } from "../../routes";

const Page = ({
    title,
    to,
    className = "",
    layoutClassName = "",
    children,
    style,
}: {
    layoutClassName?: string;
    className?: string;
    title: string;
    to?: string;
    children: React.ReactNode;
    style?: CSSProperties;
}) => (
    <Layout to={to} title={title} className={layoutClassName}>
        <SEO title={title} />
        <div
            className={`flex-auto w-full flex-col md:flex-row relative z-10 ${className}`}
            style={style}
        >
            {children}
            <footer
                className={"p-5 font-sans text-center bg-gray-700 text-white"}
            >
                &copy; {new Date().getFullYear()} - <strong>Emma Fabre</strong>{" "}
                - <Link to={ABOUT}>About</Link>
            </footer>
        </div>
    </Layout>
);

export default Page;
