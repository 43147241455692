export default {
    about: "indigo",
    blog: "purple",
    illustration: "green",
    music: "orange",
    openSource: "teal",
    photography: "pink",
    publicSpeaking: "indigo",
    stories: "indigo",
    todayIsSunday: "blue",
    webcomic: "blue",
};
