import React from "react";

const TextBlock = ({
    children,
    backgroundColor = "white",
    textColor = "black",
    fontSize = "2xl",
    padding = 32,
    className = "",
}) => (
    <div
        className={`font-sans font-thin p-6 xl:p-${padding} text-base text-justify md:text-${fontSize} bg-${backgroundColor} text-${textColor} m-0 ${className}`}
    >
        {children}
    </div>
);

export default TextBlock;
