import React from "react";
import { Query } from "..";
import Page from "../components/Layout/Page";
import GatsbyImage, { FixedObject, FluidObject } from "gatsby-image";
import { graphql } from "gatsby";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";
import ResponsiveIframe from "../components/Elements/ResponsiveIframe";

const TalkAction = ({ summary, children, ...props }) => (
    <details {...props} className={"mt-5 rounded"}>
        <summary
            className={`transition text-base cursor-pointer bg-${pageColors.publicSpeaking}-600 hover:bg-${pageColors.publicSpeaking}-400 mb-5 rounded shadow-inner text-white text-shadow text-white dark:text-gray-300 font-bold p-2 px-5`}
        >
            {summary}
        </summary>
        <div className={`bg-gray-400 p-5 rounded shadow-2xl`}>{children}</div>
    </details>
);

const PublicSpeaking = ({
    data: {
        allSpeakerdeckTalk: { nodes: talks, totalCount },
    },
}: {
    data: Query;
}) => (
    <Page title={pageTitles.publicSpeaking}>
        <PageHeader
            color={pageColors.publicSpeaking}
            counts={[`${totalCount} talks`]}
        >
            I've always had a great interest in sharing knowledge, and I believe
            it is (or should always be) one of the core tenets of the Internet:
            propagating ideas.
            <br />
            In the context of madewithlove I was given the opportunity to
            regularly talk about various subjects, in and outside the company,
            those are the talks that resulted from it.
        </PageHeader>
        {talks.map((talk) => (
            <article
                key={talk.title}
                className={`flex flex-col lg:flex-row p-6 md:p-12 lg:p-16 xl:p-24 font-sans bg-white hover:bg-gray-200 dark:bg-${pageColors.publicSpeaking}-800 dark-hover:bg-${pageColors.publicSpeaking}-700 dark:text-white transition`}
            >
                <figure className={"mr-10 hidden lg:block"}>
                    <GatsbyImage
                        className={"rounded shadow-xl"}
                        fixed={talk.image.childImageSharp.fixed as FixedObject}
                    />
                </figure>
                <figure className={"mb-10 lg:hidden"}>
                    <GatsbyImage
                        className={"rounded shadow-xl "}
                        style={{ maxHeight: 350 }}
                        fluid={talk.image.childImageSharp.fluid as FluidObject}
                    />
                </figure>
                <div className={"flex-auto"}>
                    <h1
                        className={`m-0 text-${pageColors.publicSpeaking}-600 dark:text-white text-3xl lg:text-6xl font-hairline font-light`}
                    >
                        {talk.title}
                    </h1>
                    <small className={"text-gray-500"}>{talk.date}</small>
                    <p>{talk.description}</p>
                    {talk.video && (
                        <TalkAction summary={"Watch the talk"}>
                            <ResponsiveIframe
                                title={talk.title}
                                src={`https://www.youtube.com/embed/${talk.video}`}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </TalkAction>
                    )}
                    <TalkAction summary={"See the slides"}>
                        <ResponsiveIframe
                            title={talk.title}
                            src={talk.embed}
                            allow="autoplay"
                            scrolling="no"
                        />
                    </TalkAction>
                </div>
            </article>
        ))}
    </Page>
);

export const query = graphql`
    {
        allSpeakerdeckTalk(sort: { fields: date, order: DESC }) {
            totalCount
            nodes {
                id
                description
                title
                views
                embed
                video
                date(formatString: "MMMM YYYY")
                image {
                    childImageSharp {
                        fixed(quality: 90, width: 300, height: 300) {
                            ...GatsbyImageSharpFixed
                        }
                        fluid(maxHeight: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default PublicSpeaking;
