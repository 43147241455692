import TextBlock from "./TextBlock";
import Count from "./Count";
import React, { Fragment } from "react";

const PageHeader = ({ color, counts, children, ...props }) => (
    <>
        <TextBlock
            backgroundColor={color + "-500"}
            textColor={"white"}
            {...props}
        >
            {children}
        </TextBlock>
        <Count backgroundColor={color + "-700"}>
            {counts.map((piece) => (
                <Fragment key={piece}>{piece}</Fragment>
            ))}
        </Count>
    </>
);

export default PageHeader;
