import React from "react";

const Count = ({
    children,
    backgroundColor = "black",
    textColor = "white",
}) => (
    <div
        className={`p-5 font-sans font-thin text-center bg-${backgroundColor} text-${textColor} m-0`}
    >
        {children}
    </div>
);

export default Count;
